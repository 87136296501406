<template>
  <div class="pa-4 h-100-p w-100-p">
    <div class="title mb-4">
      <h1>Collocations</h1>
    </div>

    <div class="w-100-p" v-if="folders.length > 0">
      <v-container class="pa-0 pl-4">
        <v-row>
          <v-col cols="3">
            <v-select
                v-model="selectedField"
                :items="fieldOptions"
                label="Select your fields"
                single-line
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
                v-model="centerWord"
                type="text"
                label="Center word"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
                v-model="pos"
                type="text"
                label="PoS of Center Word"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn type="submit" color="primary ml-3" @click="applySearch()" :disabled="getApiPending">
              Search
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <div class="pl-4">
        <div class="table-container" v-resize="onResize">
          <v-data-table
              class="elevation-1 collocation-table"
              fixed-header
              :headers="headers"
              :items="collocations"
              :height="windowSize.y - 358"
              :search="search"
              :custom-filter="$helper.CustomFilter.replaceSpaceWithDashFilter"
              :server-items-length="totalElements"
              :footer-props="{
                'items-per-page-options': [10, 20, 30]
              }"
          >
            <template v-slot:top>
              <v-text-field
                  v-model="search"
                  label="Search"
                  class="mx-4"

              ></v-text-field>
            </template>
            <template slot="item.id" slot-scope="props">
              {{ props.index + 1 }}
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <div class="w-100-p" v-else>
      <h4 class="pa-4 no-folder-display">Please add folder to use collocation</h4>
    </div>
    <InfoSnackbar :snackbar="showSnackbar" :text="snackbarText" @closeSnackBar="closeSnackBar()">
    </InfoSnackbar>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import InfoSnackbar from '../components/reuseable/info-snackbar';

export default {
  components: {InfoSnackbar},
  computed: {
    ...mapGetters([
      'getFolders',
      'getCollocationFieldSearch',
      'getCollocationCenterWordSearch',
      'getApiPending',
    ])
  },
  created() {
    if (this.getCollocationFieldSearch) {
      this.selectedField = this.getCollocationFieldSearch;
    }

    if (this.getCollocationCenterWordSearch) {
      this.centerWord = this.getCollocationCenterWordSearch;
    }
  },
  watch: {
    getFolders: {
      immediate: true,
      handler(folders) {
        this.folders = Object.keys(folders);
        this.fieldOptions = ["All"].concat(this.folders);
      }
    }
  },
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },
    closeSnackBar() {
      this.showSnackbar = false;
    },
    applySearch() {
      if (!this.selectedField || this.searchValue === '' || !this.centerWord) {
        return;
      }

      if (this.centerWord === this.lastCenterWord && this.pos === this.lastPos
        && this.lastSearchBy === this.selectedField) {
        return;
      }

      this.$store.dispatch("setCollocationFieldSearch", this.selectedField);
      this.$store.dispatch("setCollocationCenterWordSearch", this.centerWord);

      this.query = {
        corpus: this.selectedField === "All",
        field: this.selectedField !== "All",
        folder: this.selectedField !== "All" ? this.selectedField : null,
        token: this.centerWord.split(' ').join("_"),
        pos: this.pos === '' ? '' : this.pos,
      };

      this.lastCenterWord = this.centerWord;
      this.lastPos = this.pos;
      this.lastSearchBy = this.selectedField;

      this.getCollocation(this.query);
    },

    async getCollocation(query) {

      this.$store.dispatch('setApiPending', true);
      try {
        let res = await this.$api.preprocess.collocations({query});
        this.$store.dispatch('setApiPending', false);

        if (res.status === 200 && res.data) {
          this.totalElements = res.data.count;
          this.collocations = [];
          const collocations = res.data.collocation;
          for (const [key, value] of Object.entries(collocations)) {

            this.collocations.push({
              pivot: this.centerWord.split(" ").join("_"),
              word: key,
              L1: value.L1 || 0,
              L2: value.L2 || 0,
              L3: value.L3 || 0,
              L4: value.L4 || 0,
              L5: value.L5 || 0,
              R1: value.R1 || 0,
              R2: value.R2 || 0,
              R3: value.R3 || 0,
              R4: value.R4 || 0,
              R5: value.R5 || 0,
              totalL: (value.L1 || 0) + (value.L2 || 0) + (value.L3 || 0) + (value.L4 || 0) + (value.L5 || 0),
              totalR: (value.R1 || 0) + (value.R2 || 0) + (value.R3 || 0) + (value.R4 || 0) + (value.R5 || 0),
            });

          }
        } else {
          this.snackbarText = JSON.stringify(res.data["err"]);
          this.showSnackbar = true;

        }
      } catch (error) {
        this.$store.dispatch('setApiPending', false);
        this.snackbarText = JSON.stringify(error);
        this.showSnackbar = true;
      }
    }
  },
  data: function () {
    return {
      folders: [],
      fieldOptions: [],
      selectedField: null,
      centerWord: null,
      collocations: [],
      search: '',
      pos: '',
      windowSize: {
        x: 0,
        y: 0,
      },
      showSnackbar: false,
      snackbarText: '',
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: false,
        },
        {
          text: "Related Word",
          align: "start",
          value: "word",
          sortable: false,
        },
        {
          text: "Center Word",
          align: "start",
          value: "pivot",
          sortable: false,
        },
        {
          text: "Total_L",
          align: "start",
          value: "totalL",
          sortable: true,
        },
        {
          text: "Total_R",
          align: "start",
          value: "totalR",
          sortable: true,
        },
        {
          text: "L5",
          align: "start",
          value: "L5",
          sortable: true,
        },
        {
          text: "L4",
          align: "start",
          value: "L4",
          sortable: true,
        },
        {
          text: "L3",
          align: "start",
          value: "L3",
          sortable: true,
        },
        {
          text: "L2",
          align: "start",
          value: "L2",
          sortable: true,
        }, {
          text: "L1",
          align: "start",
          value: "L1",
          sortable: true,
        },
        {
          text: "R1",
          align: "start",
          value: "R1",
          sortable: true,
        },
        {
          text: "R2",
          align: "start",
          value: "R2",
          sortable: true,
        },
        {
          text: "R3",
          align: "start",
          value: "R3",
          sortable: true,
        },
        {
          text: "R4",
          align: "start",
          value: "R4",
          sortable: true,
        },
        {
          text: "R5",
          align: "start",
          value: "R5",
          sortable: true,
        },
      ],
      totalElements: 0,
      query: null,
      lastCenterWord: '',
      lastPos: '',
      lastSearchBy: 'All'
    };
  },
};
</script>

<style scoped>
.collocation-table {
  overflow-x: auto;
  overflow-y: hidden;
}
</style>
